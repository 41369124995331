<!--  -->
<template>
  <div>
    <!-- <div v-wechat-title="$route.meta.title"></div> -->
    
    <div class="img">
      <img src="../static/image/rule/rule@2x.png" >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created(){
    document.title='规则说明'
  }
};
</script>
<style scoped>
  
  img{
    width: 100%;
    /* height: 100%; */
  }
</style>